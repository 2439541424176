import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => {
  const productName = " UFlow "
  const companyFullName = "产品相关开发和运营主体"
  const companyName = "我们"
  const domain = "uflow.ubug.io"
  return (
    <Layout>
      <SEO title={`${productName} 用户协议`} />
      <h1 className="sp-title">{productName} 用户协议</h1>
      <div className="sp-body">
        <div className="sp-content">
          <p>
            《{productName} 产品及使用许可协议》（以下简称“本协议”）是由您与
            {companyFullName}（以下简称“{companyName}”）之间关于使用{" "}
            {productName}
            产品和服务所达成的协议。在您开始开始使用 {productName}
            产品和服务之前，请您务必审慎阅读（未成年人应在法定监护人陪同下阅读）并充分理解协议中各条款内容。
          </p>
          <p>
            如果您对本协议的任何条款表示异议，请您立即停止注册及使用{" "}
            {productName}
            所提供的全部服务。您一旦注册
            {
              productName
            }，即视为您已充分理解并完全同意本协议的各项内容，包括 {productName}
            对用户协议随时所做的任何修改。
          </p>
          <p>
            本协议可能因国家政策、产品以及履行环境发生变化而进行修改，
            {companyName}
            有权在必要时变更本协议条款，更新后的协议条款一旦公布即代替原来的协议条款，恕不另行通知。若您不接受修改后的条款，请立即停止使用
            {productName} 提供的产品和服务。您继续使用 {productName}
            提供的产品和服务将被视为已接受了修改后的协议。
          </p>
          <h2>1. 总则</h2>
          <ul>
            <li>
              {productName}{" "}
              产品及服务的所有权和运营权，以及业务规则及活动的解释权均归
              {companyName}所有。
            </li>
            <li>
              您应遵守本协议的各项条款，合法合理地使用 {productName}
              提供的产品和服务，否则，{productName}
              有权依据本协议终端或终止为您提供服务。同时，{productName}
              保留在任何时候收回您所使用的账号的权利。
            </li>
          </ul>
          <h2>2. 协议范围</h2>
          <ul>
            <li>
              本协议约定了{companyFullName}与用户之间关于使用 {productName}
              产品和服务所产生的权利义务关系。
            </li>
            <li>
              {companyName}：是指{companyFullName}，但就本协议涉及、及{" "}
              {productName}
              产品将来可能涉及的某些服务项目，{companyName}
              的关联企业也可能向您提供产品和服务，与您发生权利义务关系。
            </li>
            <li>
              您：又称用户，是指任何以合法的方式获取和使用 {productName}
              产品的人，不限于自然人或机构。
            </li>
            <li>
              {productName}产品：又称 {productName} 或 {productName}{" "}
              软件，是指由{companyName}
              合法拥有并运营的、标注名称为 {productName}
              的移动客户端应用程序以及对应的域名为 &quot;{domain}&quot;
              的移动网站。客户端应用程序以软件形式提供，包括但不限于iOS、Android等多个版本，用户必须选择与所安装终端设备相匹配的软件版本。
            </li>
            <li>
              {productName}服务：指{companyName}向您的与 {productName}{" "}
              产品相关的各项运营服务。
            </li>
            <li>
              本协议内容同时包括{companyName}及其关联企业可能不断发布的关于
              {productName}
              产品和服务的业务规则、相关协议及其修订版本等内容，包括修改产品和服务名称。上述内容一经正式发布，即为本协议不可分割的组成部分，用户同样应当遵守。
            </li>
          </ul>
          <h2>3. 产品与服务</h2>
          <ul>
            <li>
              用户可以从合法的渠道下载 {productName}
              产品到其合法拥有的终端设备中。但除非得到特别的授权，否则，用户不得以任何形式改编、复制或交易
              {productName} 产品。如果你从未经{companyName}授权的渠道获取{" "}
              {productName} 软件或与
              {productName} 名称相同的安装程序，{companyName}
              无法保证该软件能够正常使用，并对因此给用户造成的损失不予负责。
            </li>
            <li>
              一旦用户在其终端设备中打开 {productName} 产品，即视为使用{" "}
              {productName}
              产品和服务。为充分实现 {productName}
              产品及服务的全部功能，用户可能需要将其终端设备联网。
            </li>
            <li>
              {companyName}
              授权用户拥有中华人民共和国境内永久地、不可转让地、非独占地和非商业性地使用
              {productName} 产品和服务的权利，但该权利不可转让，{companyName}
              也保留在任何必要情形下收回该授权的权利。
            </li>
            <li>
              为了保证 {productName} 产品和服务的安全性和功能的一致性，
              {companyName}
              有权不经向用户特别通知而对软件进行更新，或者对软件的部分功能效果进行改变或限制。
            </li>
            <li>
              {productName} 软件新版本发布后，旧版本的软件可能无法使用。
              {companyName}
              不保证旧版本软件继续可用，请用户随时核对并下载最新版本。
            </li>
            <li>
              用户理解并同意：为了提供有效的服务， {productName}
              会利用用户终端设备的处理器和带宽等资源。 {productName}
              使用过程中可能会产生数据流量费用，用户需自行向运营商了解相关资费信息，并自行承担相关费用。
            </li>
            <li>
              {companyName}有权根据实际情况自行决定单个用户在 {productName}
              产品和服务中数据的最长储存期限，并在服务器上为其分配的存储空间。用户可根据自己的需要自行备份本软件及服务中的相关数据。
            </li>
            <li>
              如果用户停止使用本软件及服务或服务被终止或取消，{companyName}
              可以从服务器上永久地删除用户的数据。服务停止、终止或取消后，
              {companyName}没有义务向用户返还任何数据。
            </li>
          </ul>
          <h2>4. 产品与服务</h2>
          <ul>
            <li>
              除非本协议另有其它明示规定， {productName}
              增加或强化目前本服务的任何新功能，包括所推出的新产品，均受本使用协议之规范。
            </li>
            <li>
              用户了解并同意，{productName}
              之服务、产品与资料仅依其当前所呈现的状况提供，而且 {productName}
              明确地表示拒绝对于“服务”、“资料”或“产品”等给予任何明示或暗示之担保或保证，包括但不限于，为商业使用、适合于特定目的或未侵害他人权利之担保或保证等。
              {productName} 对于因“服务”、
              “产品”或“资料”等所产生之任何直接、间接、附带的或因此而导致之衍生性损失概不负责。
              {productName}
              对于任何用户信息或个性化设定之时效、删除、传递错误、未予储存或其它任何问题，均不承担任何责任。
            </li>
            <li>
              您同意 {productName}
              有权制订关于使用本服务的一般措施及限制，包括但不限于本服务将保留所发布内容或其它发布内容之最长期间，以及一定期间内您使用本服务之次数上限（及/或每次使用时间之上限）。通过本服务发布或传送之任何信息、通讯资料和其它内容，如被删除或未予储存，您同意
              {productName}无须承担任何责任。您也同意，{productName}
              有权依其自行之考虑，不论通知与否，随时变更这些一般措施及限制。
            </li>
          </ul>
          <h2>5. 用户账号</h2>
          <ul>
            <li>
              为了能使用本服务，按照中国法律的要求，您同意以下事项：依本服务注册提示填写您正确的注册邮箱、密码、名号、手机号码等信息，并确保今后更新的登录邮箱、名号、头像及手机号码等资料的真实性、有效性和合法性。若您提供任何违法、不道德或
              {productName} 认为不适合在 {productName} 网上展示的资料；或者{" "}
              {productName}
              有理由怀疑您的行为属于程序或恶意操作， {productName}
              有权无须事先通知即可暂停或终止您使用账号，并拒绝您于现在和未来使用本服务之全部或任何部分。
            </li>
            <li>
              {productName}
              无须对您（任何用户）的任何注册或登记资料承担任何责任，包括但不限于鉴别、核实任何注册或登记资料的真实性、正确性、完整性、适用性及/或是否为最新资料的责任。
            </li>
            <li>
              完成本服务的注册程序并成功注册之后，您可使用您的注册邮箱/手机号码和密码，登录到您在
              {productName}
              的账号（下称“账号”）。注册完成之时，您便获得了账号的使用权。保护账号安全，是您的责任。
            </li>
            <li>
              <p>您应对所有使用您的账号的行为负完全的责任。您同意：</p>
              <ul>
                <li>
                  您的 {productName}
                  账号遭到未获授权的使用，或者发生其它任何安全问题时，您将立即通知
                  {productName} ；
                </li>
                <li>
                  如果您未保管好自己的账号和密码，因此而产生的任何损失或损害，
                  {productName}
                  不承担任何责任；
                </li>
                <li>
                  您要对使用账号的所有行为给您、 {productName}
                  或第三方造成的损害负全责，包括您未保管好自己的账号或密码的情形。
                </li>
              </ul>
            </li>
            <li>
              由于通过账号可获取到用户的个人信息，且账号的所有权归 {productName}
              所有，除法律明文规定外，未经 {productName}
              同意，用户不得将账号转让、出售或出借给他人使用。
            </li>
            <li>
              <p>
                您选择将 {productName}
                账号与第三方账号进行绑定的，除您自行解除绑定关系外，如发生下列任何一种情形，您已绑定的第三方账号也有可能被解除绑定而
                {productName} 无需对您或任何第三方承担任何责任：
              </p>
              <ul>
                <li>您违反法律法规、国家政策或本协议的；</li>
                <li>您违反第三方账户用户协议或其相关规定的；</li>
                <li>其他需要解除绑定的情形。</li>
              </ul>
            </li>
          </ul>
          <h2>6. 个人信息和隐私</h2>
          <ul>
            <li>
              保护您个人信息安全既是法律要求，也是 {productName}
              长期坚持的一项基本原则。您提供的注册资料及 {productName}
              保留的有关您的其它资料将受到中国有关法律的保护。 {productName}
              将根据中国法律、本协议、
              <a href={`https://${domain}/privacy`} target="_blank">
                《隐私声明》
              </a>
              的规定收集、存储、使用和透露您的个人信息。
            </li>
            <li>
              一般情况下，您可随时浏览、修改您在 {productName}
              上的信息，但出于安全性和身份识别的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。
            </li>
          </ul>
          <h2>7. 用户行为</h2>
          <ul>
            <li>
              您应遵守中华人民共和国相关法律法规，并同意对以任何方式使用您的登录账号而使用本服务的任何行为及其结果承担全部责任。如您的行为违反国家法律，您将依法承担全部法律责任；如涉嫌构成犯罪，司法机关将追究您的刑事责任，
              {productName} 将严格按照法律规定的义务及司法机关的要求进行配合。
              同时，如果 {productName}
              有理由认为您的任何行为，包括但不限于您的任何言论或其它行为违反或可能违反国家法律法规的任何规定，
              {productName} 可在任何时候不经任何事先通知终止向您提供服务。
            </li>
            <li>
              <p>
                您同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为∶
              </p>
              <ul>
                <li>
                  发布或以其他方式传送含有下列内容之一的信息：
                  <ul>
                    <li>反对宪法所确定的基本原则的；</li>
                    <li>
                      危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
                    </li>
                    <li>损害国家荣誉和利益的；</li>
                    <li>煽动民族仇恨、民族歧视、破坏民族团结的；</li>
                    <li>破坏国家宗教政策，宣扬邪教和封建迷信的；</li>
                    <li>散布谣言，扰乱社会秩序，破坏社会稳定的；</li>
                    <li>
                      散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
                    </li>
                    <li>侮辱或者诽谤他人，侵害他人合法权利的；</li>
                    <li>
                      含有虚假、诈骗、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其他道德上令人反感的内容；
                    </li>
                    <li>
                      含有中国法律、法规、（部门）规章以及任何具有法律效力之规范所限制或禁止的其他内容的；
                    </li>
                    <li>
                      含有 {productName} 认为不适合在 {productName} 展示的内容；
                    </li>
                  </ul>
                </li>
                <li>以任何方式危害他人的合法权益；</li>
                <li>
                  冒充其他任何人或机构，或以虚伪不实的方式陈述或谎称与任何人或机构有关；
                </li>
                <li>
                  将依据任何法律、合约或法定关系（例如由于雇佣关系和依据保密合约所得知或揭露之内部资料、专属及机密资料）而知悉但无权传送之任何内容加以发布、发送电子邮件或以其它方式传送；
                </li>
                <li>
                  将侵害他人著作权、专利权、商标权、商业秘密、或其他专属权利（以下简称“专属权利”）之内容加以发布或以其他方式传送；
                </li>
                <li>
                  将任何“广告信函”、“促销资料”、“垃圾邮件”、“滥发信件”、“连锁信件”、“直销”或其他任何形式的劝诱资料加以发布、发送或以其它方式传送；
                </li>
                <li>
                  将设计目的在于干扰、破坏或限制任何计算机软件、硬件或通讯设备功能之计算机病毒（包括但不限于木马程序（trojan
                  horses）、蠕虫（worms）、定时炸弹、删除蝇（cancelbots）（以下简称“病毒”）或其他计算机代码、档案和程序之任何资料，加以发布、发送或以其他方式传送；
                </li>
                <li>
                  干扰或破坏本服务或与本服务相连线之服务器和网络，或违反任何关于本服务连线网络之规定、程序、政策或规范；
                </li>
                <li>跟踪、人肉搜索或以其他方式骚扰他人；</li>
                <li>
                  故意或非故意地违反任何适用的当地、国家法律，以及任何具有法律效力的规则；
                </li>
                <li>
                  未经合法授权而截获、篡改、收集、储存或删除您或他人的个人信息、站内邮件或其他数据资料，或将获知的此类资料用于任何非法或不正当目的。
                </li>
              </ul>
              <p>
                您已认可未对用户的使用行为进行全面控制，您使用任何内容时，包括依赖前述内容之正确性、完整性或有效性时，您同意将自行加以判断并承担所有风险，而不依赖于
                {productName} 。但 {productName}
                依其自行之考虑，有权拒绝和删除经由本服务提供之违反本条款的或其他引起
                {productName} 反感的任何内容。
              </p>
            </li>
            <li>
              由于您通过本服务提供、发布或传送之内容、您与本服务连线、您违反本使用协议、或您侵害他人任何权利因而衍生或导致任何第三人提出任何索赔或请求，包括但不限于合理的律师费、诉讼费，您同意赔偿
              {productName}
              及其子公司、关联公司、高级职员、代理人、品牌共有人或其他合作伙伴及员工，并使其免受损害，并承担由此引发的全部法律责任。
            </li>
            <li>
              您同意不对本服务任何部分或本服务之使用或获得，进行复制、拷贝、出售、转售或用于任何其他商业目的。
            </li>
          </ul>
          <h2>8. 知识产权</h2>
          <ul>
            <li>
              未经相关权利人同意，用户不得对 {productName}
              产品和服务涉及的相关网页、应用、软件等产品进行反向工程、反向汇编、反向编译等。
            </li>
            <li>
              {companyName}是 {productName} 产品和服务的知识产权权利人。
              {productName}
              享有的或可能享有的一切商标权、著作权、专利权和商业秘密等知识产权，以及由
              {companyName}构思创作并提供的与 {productName}
              产品和服务相关的信息内容（包括但不限于网页、界面设计、版面框架、文字、图片、作品汇编、改编、有关数据等）的知识产权，均归属于
              {companyName}所有。
            </li>
            <li>
              {productName}{" "}
              产品和服务中所包含的内容的知识产权均受到法律保护，未经
              {companyName}
              、用户或相关权利人书面许可，任何人不得以任何形式进行使用或创造相关衍生作品。
            </li>
            <li>
              您在 {productName}
              上传或发布的内容，您应保证为其著作权人或已取得合法授权，并且该内容不会侵犯任何第三方的合法权益。您点击同意本协议，即表明您同意授予
              {productName}
              所有上述内容在全球范围内的、免费的、不可撤销的、无期限限制的、可再许可或转让的非独家著作财产权使用权许可，据该许可
              {productName}
              将有权以展示、推广及其他不为我国法律所禁止的方式使用前述内容。同时您同意许可
              {productName}
              视具体情况就任何主体侵犯您权益的事宜进行维权，包括但不限于发送侵权函件、提起诉讼、申请仲裁等，
              {productName}
              无需再向您单独获得授权。本协议已经构成《著作权法》第二十五条所规定的书面协议，其效力及于用户在
              {productName}
              网发布的任何受著作权法保护的内容，无论该内容形成于本协议签订前还是本协议签订后。
            </li>
            <li>
              {productName}{" "}
              提供的第三方内容版权均属于权利人和相关创作者，您应该保证不会订阅涉及隐私、金融数据、商业机密、国家规定禁止传播的内容等，您订阅的第三方内容应保证取得第三方内容合法授权，包括但不限于公开提供、公开包含订阅协议链接或者分享
              RSS 或者 ATOM 等信息流协议等。
              {productName}{" "}
              仅提供标准内容订阅协议的执行和必要信息存储，不对存储内容的法律侵害负责。如您违反约定存储或者传播相关内容或者相关订阅和收集的行为，造成他人（包括
              {productName}）的权利侵害，您应承担全部的法律责任。
            </li>
            <li>
              如您违反世界版权公约、中华人民共和国著作权法、商标法、专利法、反不正当竞争法及其他与知识产权方面相关的法律法规或本协议约定，您应自行承担因此而给他人（包括
              {productName}）造成的损害，承担相应的法律责任。
            </li>
          </ul>
          <h2>9. 服务变更、中断或终止</h2>
          <ul>
            <li>
              {productName} 可能会对服务内容进行变更、也可能会中断、终止服务。
            </li>
            <li>
              鉴于网络服务的特殊性（包括但不限于不可抗力、恶意的网络攻击、服务器的稳定性问题、网络环境、第三方服务瑕疵、政府行为或
              {productName} 无法控制的情形），您同意 {productName}
              有权随时中断或终止部分或全部的服务。
            </li>
            <li>
              您理解并同意， {productName}
              需要定期或不定期对提供服务的平台或相关设备进行维护、升级或其他目的暂停部分或全部服务，如因此类情况而造成服务在合理时间内的中断，
              {productName} 无需为此承担任何责任。
            </li>
            <li>
              您理解并同意，{productName}
              为了服务整体运营的需要，有权在不事先通知用户的情况下修改、中断、中止或终止
              {productName}
              产品内的各项服务，而无须向用户或第三方负责或承担任何赔偿责任。
            </li>
            <li>
              如发生以下任何一种情形， {productName}
              有权随时中断或终止向您提供本协议项下的服务而无需对您或任何第三方承担责任：
              <ul>
                <li>您提供个人资料不真实；</li>
                <li>您违反法律、政策或违反本使用协议。</li>
              </ul>
            </li>
            <li>
              您同意 {productName}
              基于其自行之考虑，因任何理由，包含但不限于缺乏使用，或{" "}
              {productName}
              认为您已经违反本使用协议，终止您的账号或本服务之使用（或服务之任何部分），并将您在本服务内任何内容加以移除并删除。您同意依本使用协议任何规定提供之服务，无需进行事先通知即可中断或终止。您承认并同意，
              {productName}
              可立即关闭或注销您的账号及删除您账号中所有相关信息及文件，及/或禁止继续使用前述文件或本服务。此外，您同意若本服务之使用被中断或终止或您的账号及相关信息和文件被关闭或注销，
              {productName}
              对您或任何第三人均不承担任何责任。
            </li>
          </ul>
          <h2>10. 法律责任声明</h2>
          <ul>
            <li>
              {companyName}对于任何自 {productName}
              产品和服务中获得的他人的信息、内容或者广告宣传等任何资讯（以下统称“讯息”），不负保证真实、准确和完整性的责任。如果任何单位或者个人通过上述“讯息”而进行任何行为，须自行甄别真伪和谨慎预防风险，否则，无论何种原因，
              {companyName}
              不对任何非与本应用程序直接发生的交易和（或）行为承担任何直接、间接、附带或衍生的损失和责任。
            </li>
            <li>
              {companyName}
              不保证产品和服务完全适合用户的使用要求；不保证产品或服务不受干扰，及时、安全、可靠，或不出现错误用户；不保证经由
              {companyName}
              取得的任何产品、服务或其他材料符合用户的期望；不保证产品或服务中任何错误都将能得到更正。
            </li>
            <li>
              <p>
                基于以下原因而造成的利润、商业信誉、资料损失或其他有形或无形损失，
                {companyName}
                不承担任何直接、间接、附带、特别、衍生性或惩罚性的赔偿责任（即使
                {companyName}事先已被告知发生此种赔偿之可能性亦然）：
              </p>
              <ul>
                <li>对{companyName}的产品的使用或无法使用；</li>
                <li>
                  {companyName}
                  的产品不受干扰，及时、安全、可靠或不出现错误；用户经由
                  {companyName}取得的任何产品、服务或其他材料符合用户的期望；
                </li>
                <li>产品中的任何错误或不稳定；</li>
                <li>任何第三方在本产品服务中所作的声明或行为；</li>
                <li>
                  用户基于在产品或服务中发布的广告信息，向第三方购买商品或服务；
                </li>
                <li>
                  其他与{companyName}相关的事宜，但本用户协议有明确规定的除外。
                </li>
              </ul>
            </li>
            <li>
              对违反有关法律法规或本协议规定的行为，{companyName}
              将依法律规定及上述规则等加以合理判断进行处理，对违法违规的任何人士采取适当的法律行动，并依据法律法规保存有关信息并向有关部门报告等。
            </li>
          </ul>
          <h2>11. 未成年人使用条例</h2>
          <p>
            如您是未成年用户，请在法定监护人的陪同下仔细阅读本使用协议，您及法定监护人应按照法律及本协议规定承担责任。
          </p>
          <h2>12. 违约责任</h2>
          <ul>
            <li>
              用户因违反国家法律法规或本协议的约定，或用户侵害他人任何权利因而衍生或导致任何第三人向
              {companyName}
              提出任何索赔或请求，包括但不限于诉讼费用、律师费用、差旅费用、和解金额、罚款或生效法律文书中规定的损害赔偿金额、软件使用费等而给
              {companyName}造成损失的，用户应赔偿{companyName}
              因此而遭受的一切损失，并消除影响。
            </li>
            <li>
              如果{companyName}发现或收到他人举报或投诉用户违反本协议约定的，
              {companyName}
              有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规账号处以包括但不限于警告、限制或禁止使用部分或全部功能、账号封禁直至注销的处罚，并有权公告处理结果。
            </li>
          </ul>
          <h2>13. 其他条例</h2>
          <ul>
            <li>
              {productName} 公布的
              <a href={`https://${domain}/privacy`} target="_blank">
                《隐私声明》
              </a>
              均是本协议的有效组成部分，本协议及与 {productName}
              服务相关的指引和规则共同规范您对于本服务之使用行为。在您使用相关服务、使用第三方提供的内容或软件时，亦应遵从所适用之附加条款及条件。
            </li>
            <li>
              本使用协议及您与{companyName}
              之关系，均适用中华人民共和国法律。您与{companyName}
              就本服务、本使用协议或其他有关事项发生的争议，应首先友好协商解决，协商不成时应提请
              {companyName}实际运营者所在地有管辖权的人民法院通过诉讼解决。
            </li>
            <li>
              {companyName}
              未行使或执行本使用协议任何权利或规定，不构成对前述权利或权利之放弃。
            </li>
            <li>
              本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。倘本协议之任何规定因与中国法律抵触而无效，则这些条款将尽可能接近本协议原条文意旨重新解析，且本协议其它规定仍应具有完整的效力及效果。
            </li>
            <li>
              本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据，不具任何法律或契约效果。
            </li>
            <li>
              本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export default SecondPage
